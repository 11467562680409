import React from "react";
import { defaultTheme, majorScale, Link, Pane, Text } from "evergreen-ui";
import { SectionHeader } from "./SectionHeader";

function Snippet({ name, url, description }) {
  return (
    <Pane marginTop={majorScale(4)} display="flex">
      <Link href={url} target="_blank">
        <Text color={defaultTheme.colors.text.selected}>{name}</Text>
      </Link>
      <Text marginLeft={majorScale(1)}>{description}</Text>
    </Pane>
  );
}

export function CodeSnippets() {
  return (
    <Pane
      paddingY={majorScale(4)}
      maxWidth={864}
      paddingX={majorScale(2)}
      margin="auto"
    >
      <SectionHeader>Code Snippets</SectionHeader>

      <Snippet
        name="Elixir Pattern Matching for Rubyists"
        url="https://gist.github.com/MrAlexLau/2184ba625e79bcd170abafcb3e9c7feb"
        description={
          "An explanation of Elixir's pattern matching with examples in both Ruby and Elixir."
        }
      />

      <Snippet
        name="Procs, Blocks, and Lambdas"
        url="https://gist.github.com/MrAlexLau/703aedad2dd62825fa5b"
        description={
          "Exploring Procs, blocks, and lambda expressions by example in Ruby."
        }
      />
    </Pane>
  );
}

import React from "react";
import {
  defaultTheme,
  Heading,
  majorScale,
  Pane,
  Paragraph,
} from "evergreen-ui";
import headshot from "./alex.jpg";

export function Intro() {
  return (
    <Pane
      color="#FFF"
      backgroundColor={defaultTheme.palette.blue.dark}
      paddingY={majorScale(8)}
      margin="auto"
      textAlign="center"
    >
      <img
        style={{
          borderRadius: "50%",
          width: "150px",
          borderColor: "#ffffff6e",
          borderWidth: "3px",
          borderStyle: "solid",
        }}
        src={headshot}
        alt="alex headshot"
      />
      <Pane>
        <Heading
          size={700}
          color={defaultTheme.palette.neutral.lightest}
          margin={majorScale(3)}
        >
          Hi, I'm Alex Lau.
        </Heading>
        <Pane
          maxWidth={864 * (2 / 3) + majorScale(2)}
          margin="auto"
          paddingX={majorScale(2)}
          textAlign="left"
        >
          <Paragraph
            fontSize={20}
            color={defaultTheme.palette.neutral.lightest}
          >
            I'm a full stack web developer who has worked at companies of
            various stages and sizes, and with technologies just as diverse.
          </Paragraph>
          <Paragraph
            fontSize={20}
            color={defaultTheme.palette.neutral.lightest}
            marginTop={majorScale(2)}
          >
            Ask any of my coworkers and they'll tell you that my passion for
            software development is only matched by my desire to find the
            perfect meme for each and every occasion.
          </Paragraph>
        </Pane>
      </Pane>
    </Pane>
  );
}

import React from "react";
import { Intro } from "./Intro";
import { SideProjects } from "./SideProjects";
import { CodeSnippets } from "./CodeSnippets";
import { StackOverflowAnswers } from "./StackOverflowAnswers";

function App() {
  return (
    <div className="App">
      <Intro />
      <SideProjects />
      <CodeSnippets />
      <StackOverflowAnswers />
    </div>
  );
}

export default App;

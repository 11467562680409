import React from "react";
import { Heading, Pane } from "evergreen-ui";

export function SectionHeader({ children }) {
  return (
    <Pane width="100%" textAlign="center">
      <Heading size={600}>{children}</Heading>
    </Pane>
  );
}

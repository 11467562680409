import React from "react";
import {
  defaultTheme,
  Link,
  ListItem,
  majorScale,
  Pane,
  Paragraph,
  Text,
  UnorderedList,
} from "evergreen-ui";
import { SectionHeader } from "./SectionHeader";

function Answer({ name, url }) {
  return (
    <Pane marginTop={majorScale(2)} display="flex">
      <Link href={url} target="_blank">
        <Text color={defaultTheme.colors.text.selected}>{name}</Text>
      </Link>
    </Pane>
  );
}

export function StackOverflowAnswers() {
  return (
    <Pane
      paddingY={majorScale(4)}
      maxWidth={864}
      paddingX={majorScale(2)}
      margin="auto"
    >
      <SectionHeader>Stack Overflow Answers</SectionHeader>

      <Paragraph marginTop={majorScale(4)}>
        Here are some Stack Overflow answers I am particularly proud of because
        of how they involved looking deeply into an issue:
      </Paragraph>

      <UnorderedList>
        <ListItem>
          <Answer
            name="Ruby Time#to_date() method returns wrong date"
            url="https://stackoverflow.com/a/22561798/2339716"
          />
        </ListItem>
        <ListItem>
          <Answer
            name="simple-spreadsheet not properly loading an xls file on ruby, but working properly on irb"
            url="https://stackoverflow.com/a/22800545/2339716"
          />
        </ListItem>
      </UnorderedList>
    </Pane>
  );
}

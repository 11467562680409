import React from "react";
import { defaultTheme, majorScale, Link, Pane, Text } from "evergreen-ui";
import { SectionHeader } from "./SectionHeader";
import bitsPreview from "./bits-preview.png";
import boonPreview from "./boon-preview.png";

const CARD_BORDER_RADIUS = 7;

function SideProject({ description, name, preview, url }) {
  return (
    <Pane
      elevation={1}
      borderRadius={CARD_BORDER_RADIUS}
      width={375}
      margin={majorScale(2)}
    >
      <Pane
        borderTopLeftRadius={CARD_BORDER_RADIUS}
        borderTopRightRadius={CARD_BORDER_RADIUS}
        backgroundColor={defaultTheme.palette.neutral.light}
        padding={majorScale(2)}
        textAlign="center"
      >
        <Link href={url} target="_blank" textDecoration="none">
          <Text
            fontWeight="bold"
            size={500}
            color={defaultTheme.palette.neutral.dark}
          >
            {name}
          </Text>
        </Link>
      </Pane>

      <Pane padding={majorScale(2)}>
        <Pane textAlign="center">
          <Link href={url} target="_blank">
            <img style={{ width: "100%" }} src={preview} alt={name} />
          </Link>
        </Pane>
        <Pane marginTop={majorScale(1)}>
          <Text>{description}</Text>{" "}
          <Link href={url} target="_blank">
            Visit website
          </Link>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function SideProjects() {
  return (
    <Pane paddingX={majorScale(2)} paddingY={majorScale(4)}>
      <SectionHeader>Side Projects</SectionHeader>

      <Pane display="flex" justifyContent="center" flexWrap="wrap">
        <SideProject
          name="Boon"
          url="http://boonthegame.com"
          preview={boonPreview}
          description={
            "This is the marketing website for a card game that I co-created called Boon. The site itself is a simple Sinatra application."
          }
        />

        <SideProject
          name="Bits on Bits on Bits"
          url="http://mralexlau.github.io/blog/"
          preview={bitsPreview}
          description={
            "My (now retired) blog which contained sample code, links to resources, and several code articles. The site is published with Octopress."
          }
        />
      </Pane>
    </Pane>
  );
}
